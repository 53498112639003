import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { darkGreen } from "../../constants";
import { Link } from "react-router-dom";
import { ContentWrapper } from "../../pages/style";

const Wrapper = styled.div`
  width: 100%;
  background-color: ${darkGreen};
  color: white;
  padding: 10px 0;
  font-size: 13px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;

  @media (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

  }
`

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ContentWrapper>
        <FlexWrapper>
          <div>
            <div>{t("footer.company-name")}</div>
            <div>{t("footer.copyright")}</div>
          </div>
          <Flex>
            <StyledLink to="/terms-and-conditions">Terms</StyledLink>
            <StyledLink to="/cookie-policy">Cookies</StyledLink>
            <StyledLink to="/privacy-policy">Privacy</StyledLink>
          </Flex>
        </FlexWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Footer;
