import React, { useState } from "react";
import styled from "styled-components";
import { darkGreen } from "../../constants";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Wrapper = styled.div`
  background-color: ${darkGreen};
  padding: 25px;
  border-radius: 7px;
  color: white;
  font-size: 16px;
  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const Input = styled.div`
  padding-bottom: 30px;
  input {
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    border-style: none;
    font-size: 16px;
    font-style: italic;
  }
`;

const Checkbox = styled.div`
  padding-bottom: 20px;
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const Heading = styled.div`
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 20px;
`;

const Error = styled.div`
  color: #f52d2d;
  padding-top: 10px;
`;

const Result = styled.div`
  color: #ffffff;
  padding-top: 10px;
`;

const Form = () => {
  const { t } = useTranslation();
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState("");
  const [isSent, setIsSent] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    industry: "",
    privacy: false,
    marketing: false,
  });

  const [honeypot, setHoneypot] = useState();

  const handleChange = (e: {
    target: { name: any; value: any; type: any; checked: any };
  }) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleHoneypot = (e: {
    target: { name: any; value: any; type: any; checked: any };
  }) => {
    const { value } = e.target;
    setHoneypot(value);
  };

  const handleSubmit = async () => {
    if (honeypot) return;

    try {
      setIsSending(true);
      setError("");
      setIsSent(false)

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}:${process.env.REACT_APP_BACKEND_PORT}/send-email`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (response.status === 200) setIsSent(true)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle errors specifically related to axios
        console.error("Axios error:", error.message);
        setError(`Error: ${error.response?.data?.message || error.message}`);
      } else {
        // Handle any other errors
        console.error("Unknown error:", error);
        setError("Error: An unknown error occurred");
      }
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Wrapper>
      <Heading>{t("book-a-demo-page.book-your-demo")}</Heading>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Input>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            placeholder={t("book-a-demo-page.full-name")}
          />
        </Input>

        <Input>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder={t("book-a-demo-page.business-email")}
          />
        </Input>

        <Input>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            placeholder={t("book-a-demo-page.phone-number")}
          />
        </Input>

        <Input>
          <input
            type="text"
            name="industry"
            value={formData.industry}
            onChange={handleChange}
            required
            placeholder={t("book-a-demo-page.industry")}
          />
        </Input>

        <input
          type="text"
          name="your_high"
          style={{ display: "none" }}
          value={honeypot}
          onChange={handleHoneypot}
        />

        <Checkbox>
          <label>
            <input
              type="checkbox"
              name="privacy"
              checked={formData.privacy}
              onChange={handleChange}
            />
            {t("book-a-demo-page.privacy-agreement")}
          </label>
        </Checkbox>

        <Checkbox>
          <label>
            <input
              type="checkbox"
              name="marketing"
              checked={formData.marketing}
              onChange={handleChange}
            />
            {t("book-a-demo-page.marketing-agreement")}
          </label>
        </Checkbox>

        <Button
          type="submit"
          $isWhite
          disabled={!formData.privacy || isSending}
        >
          {t("book-a-demo-page.submit")}
        </Button>
        {error && <Error>{error}</Error>}
        {isSent && <Result> {t("book-a-demo-page.email-sent")}</Result>}

      </form>
    </Wrapper>
  );
};

export default Form;
