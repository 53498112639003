import styled from "styled-components";
import SectionMain from "./main";
import SectionReadyTo from "./ready-to";
import SectionOffer from "./offer";
import { topMargin } from "../../../constants";

const Wrapper = styled.div`
  padding-top: ${topMargin}px;
  min-height: 100vh;
`;

const SolutionsForBrands = () => {
  return (
    <Wrapper>
      <SectionMain />

      <SectionReadyTo />

      <SectionOffer />
    </Wrapper>
  );
};

export default SolutionsForBrands;
