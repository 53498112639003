import styled from "styled-components";
import SectionMain from "./main";
import SectionWhy from "./why";
import SectionHow from "./how";
import { topMargin } from "../../../constants";

const Wrapper = styled.div`
  padding-top: ${topMargin}px;
  min-height: 100vh;
`;

const SolutionsForBrands = () => {
  return (
    <Wrapper>
      <SectionMain />

      <SectionHow />

      <SectionWhy />
    </Wrapper>
  );
};

export default SolutionsForBrands;
