import styled from "styled-components";
import { topMargin } from "../../constants";
import { ContentWrapper } from "../style";

const Wrapper = styled.div`
  padding-top: ${topMargin}px;
  min-height: 100vh;
`;

const Cookies = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <h2>Cookie Policy</h2>

        <p>
          1. Introduction<br></br>
          This Cookie Policy explains how Eco Connect Hub (&quot;we,&quot;
          &quot;our,&quot; or &quot;us&quot;) uses cookies and similar
          technologies to recognize you when you visit our website and use our
          services. It explains what these technologies are, why we use them,
          and your rights to control their use.
        </p>
        <p>
          2. What Are Cookies?<br></br>
          Cookies are small text files that are placed on your device (computer,
          smartphone, tablet) when you visit a website. Cookies allow the
          website to recognize your device and store certain information about
          your preferences or past actions.
        </p>
        <p>
          3. Types of Cookies We Use<br></br>
          We use the following types of cookies:<br></br>- Essential Cookies:
          These cookies are necessary for the operation of our website. They
          enable you to navigate the site and use its features, such as
          accessing secure areas.<br></br>- Performance and Analytics Cookies:
          These cookies collect information about how you use our website, such
          as which pages you visit most often and if you encounter any errors.
          This data helps us improve the performance of our site.<br></br>-
          Functionality Cookies: These cookies allow our website to remember
          your preferences, such as your username or language settings, to
          provide a more personalized experience.<br></br>-
          Targeting/Advertising Cookies: These cookies are used to deliver
          relevant advertisements to you and track the effectiveness of our
          advertising campaigns.
        </p>
        <p>
          4. How We Use Cookies<br></br>
          We use cookies to:<br></br>- Enhance Your Experience: By remembering
          your preferences and interactions, we can make your experience more
          convenient and personalized.<br></br>- Analyze Usage: We use analytics
          cookies to track user behavior on our site, allowing us to understand
          how our services are used and identify areas for improvement.<br></br>
          - Serve Targeted Ads: We use targeting cookies to deliver relevant ads
          to you based on your browsing habits and interests.
        </p>
        <p>
          5. Third-Party Cookies<br></br>
          In addition to our own cookies, we may also use various third-party
          cookies to report usage statistics, deliver advertisements, and
          provide certain functionalities. These third parties may use cookies
          in accordance with their own privacy policies.
        </p>
        <p>
          6. Your Choices<br></br>
          You have the right to decide whether to accept or reject cookies. You
          can set your browser to refuse cookies or alert you when cookies are
          being sent. However, if you choose to refuse cookies, you may not be
          able to use some features of our website.<br></br>- Opting Out of
          Targeted Advertising: You can opt-out of targeted advertising by
          visiting the Network Advertising Initiative’s opt-out page or using
          your browser’s settings to block these cookies.
        </p>
        <p>
          7. Managing Cookies<br></br>
          Most web browsers allow you to control cookies through their settings.
          To learn more about cookies, including seeing what cookies have been
          set and how to manage and delete them, visit www.allaboutcookies.org.
        </p>
        <p>
          8. Changes to This Cookie Policy<br></br>
          We may update this Cookie Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We will notify you of any material changes by posting the new
          Cookie Policy on our platform and updating the &quot;Effective
          Date.&quot;
        </p>
        <p>
          9. Contact Us<br></br>
          If you have any questions about our use of cookies or this Cookie
          Policy, please contact us at hello@ecoconnect-hub.com.
        </p>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Cookies;
