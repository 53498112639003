import styled from "styled-components";
import { darkGreen, lightGrey } from "../../../constants";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "../../style";

const Wrapper = styled.div`
  background-size: cover;
  /* background-image: url("/assets/main_background_textured.jpg"); */
  background-color: #efefef;

`;

const Content = styled.div`
  padding: 50px 0;
`;

const Flex = styled.div`
  display: flex;
  gap: 50px;
  align-items: flex-start;
  flex-direction: column;

  > div {
    flex: 1;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Heading = styled.div`
  font-size: 40px;
  font-weight: 800;
  line-height: 1.1;
  color: ${darkGreen};
  padding-bottom: 40px;
  @media (min-width: 768px) {
    font-size: 85px;
  }
`;

const Text = styled.div`
  font-size: 20px;

  line-height: 1.2;
  white-space: pre-wrap;
  @media (min-width: 768px) {
    font-size: 23px;

  }
`;

const Image = styled.img`
  width: 100%;
`;

const SectionReadyTo = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <Heading>{t("solutions-for-researchers.ready-to.heading1")}</Heading>
          <Flex>
            <Text>{t("solutions-for-researchers.ready-to.description1")}</Text>
            <div>
              <Image src="/assets/academia_image2.png"></Image>
            </div>
          </Flex>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default SectionReadyTo;
