import styled from "styled-components";
import { darkGreen } from "../constants";

export const SubHeading = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
  color: black;
  @media (min-width: 768px) {
    padding-left: 7px;

  }
`;

export const Heading = styled.h1`
  font-size: 35px;
  font-weight: 800;
  line-height: 1.1;
  margin: 0 0 20px 0;
  @media (min-width: 768px) {
    font-size: 80px;
  }
`;

export const Content = styled.div`
  padding: 50px 0;
  color: ${darkGreen};
  width: 100%;
  z-index: 1;
  position: relative;
  @media (min-width: 768px) {
    width: 70%;
    padding: 100px 0;

  }
`;

export const Text = styled.div`
  font-size: 20px;
  padding-bottom: 20px;
  @media (min-width: 768px) {
    font-size: 25px;
  }
`;

export const BackgroundImage = styled.div`
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;

  @media (min-width: 768px) {
    background-image: url(/assets/homepage_demo.png);
    background-size: 115%;
    background-position: right -140px bottom 50%;
    background-repeat: no-repeat;
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 40px;
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
`;
