import styled from "styled-components";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "../style";

const Wrapper = styled.div`
  /* background: url("/assets/main_background_textured.jpg"); */
  background-color: #efefef;

  background-size: cover;
`;

const Content = styled.div`
  padding: 50px 0;
  display: flex;
  align-items: stretch;
  gap: 30px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Block = styled.div`
  background-color: white;
  border-radius: 5px;
  font-size: 16px;
  line-height: 1.2;
  flex-basis: calc(100% / 3);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  @media (min-width: 768px) {
    font: 19px;
  }
`;

const Heading = styled.div`
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ListElement = styled.li`
  font-size: 20px;
`;

const Icon = styled.img`
  height: 40px;
  padding-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 50px;
`;

const SectionFor = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <Block>
            <Icon src="/assets/icons/brands.png"></Icon>
            <Heading>{t("main.for-brands.heading")}</Heading>
            <List>
              <ListElement>{t("main.for-brands.feature1")}</ListElement>
              <ListElement>{t("main.for-brands.feature2")}</ListElement>
            </List>
          </Block>
          <Block>
            <Icon src="/assets/icons/suppliers.png"></Icon>
            <Heading>{t("main.for-suppliers.heading")}</Heading>
            <List>
              <ListElement>{t("main.for-suppliers.feature1")}</ListElement>
              <ListElement>{t("main.for-suppliers.feature2")}</ListElement>
            </List>
          </Block>
          <Block>
            <Icon src="/assets/icons/academia.png"></Icon>
            <Heading>{t("main.for-researchers.heading")}</Heading>
            <List>
              <ListElement>{t("main.for-researchers.feature1")}</ListElement>
              <ListElement>{t("main.for-researchers.feature2")}</ListElement>
            </List>
          </Block>
        </Content>
        <ButtonContainer>
          <Button to="/book-a-demo">{t("main.request-demo")}</Button>
        </ButtonContainer>
      </ContentWrapper>
    </Wrapper>
  );
};

export default SectionFor;
