import { useState } from "react";
import styled from "styled-components";
import { darkGreen } from "../../constants";
import { useTranslation } from "react-i18next";

const LanguageSwitcherContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
`;

const LanguageSelect = styled.select`
  font-size: 16px;
  border: 0px;
  color: ${darkGreen};
  background-color: transparent;
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none; /* Remove default arrow for Safari */
  -moz-appearance: none; /* Remove default arrow for Firefox */
  padding-right: 15px; /* Add space for the custom chevron */
  background-image: url('/assets/icons/chevron.svg'); /* Path to your chevron image */
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 12px; /* Adjust based on the size of your chevron */
  cursor: pointer;
`;

const Globe = styled.div`
  width: 20px;

  img {
    width: 100%;
  }
`;

const LanguageSwitcher = () => {
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (event: { target: { value: any } }) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <LanguageSwitcherContainer>
      <Globe>
        <img src="/assets/icons/globe.svg" alt="globe" />
      </Globe>
      <LanguageSelect value={language} onChange={handleLanguageChange}>
        <option value="en">{t("languages.english")}</option>
        <option value="fr">{t("languages.french")}</option>
      </LanguageSelect>
    </LanguageSwitcherContainer>
  );
};

export default LanguageSwitcher;
