import styled from "styled-components";
import { darkGreen, lightGrey, orange } from "../../constants";

export const Wrapper = styled.div`
  background-color: ${lightGrey};
  background-size: cover;
  /* background-image: url("/assets/main_background_textured.jpg"); */
  background-color: #efefef;

`;

export const Content = styled.div`
  padding: 50px 0;
`;

export const Block = styled.div`
  display: flex;
  gap: 50px;
  padding-bottom: 80px;
  align-items: flex-start;
  flex-direction: column-reverse;

  > div {
    flex: 1;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    &:nth-child(2n - 1) {
      flex-direction: row-reverse;
    }
  }
`;

export const BlockHeading = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: ${darkGreen};
  display: flex;
  align-items: flex-end;
  padding-bottom: 40px;
  line-height: 1.2;
  @media (min-width: 768px) {
    font-size: 50px;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  line-height: 1.2;
  white-space: pre-wrap;
  @media (min-width: 768px) {
    font-size: 23px;
  }
`;

export const Image = styled.img`
  width: 100%;
  display: inline-block;
  @media (min-width: 768px) {
    padding-top: 50px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Number = styled.div`
  color: ${orange};
  font-size: 60px;
  padding-right: 10px;
  line-height: 1;
  @media (min-width: 768px) {
    font-size: 140px;
  }
`;
