import { useTranslation } from "react-i18next";
import { ContentWrapper } from "../../style";
import Button from "../../../components/Button";
import {
  Wrapper,
  Block,
  BlockHeading,
  Number,
  ButtonWrapper,
  Image,
  Text,
  Content
} from "../styles";

const SectionOffer = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <Block>
            <div>
              <Image src="/assets/credentials_image.jpg"></Image>
            </div>
            <div>
              <BlockHeading>
                <Number>1</Number>
                <div>{t("solutions-for-suppliers.offers.offer1.heading")}</div>
              </BlockHeading>
              <Text>
                {t("solutions-for-suppliers.offers.offer1.description")}
              </Text>
            </div>
          </Block>

          <Block>
            <div>
              <Image src="/assets/green business_image.jpg"></Image>
            </div>
            <div>
              <BlockHeading>
                <Number>2</Number>
                <div>{t("solutions-for-suppliers.offers.offer2.heading")}</div>
              </BlockHeading>
              <Text>
                {t("solutions-for-suppliers.offers.offer2.description")}
              </Text>
            </div>
          </Block>

          <Block>
            <div>
              <Image src="/assets/reputation_image.jpg"></Image>
            </div>
            <div>
              <BlockHeading>
                <Number>3</Number>
                <div>{t("solutions-for-suppliers.offers.offer3.heading")}</div>
              </BlockHeading>
              <Text>
                {t("solutions-for-suppliers.offers.offer3.description")}
              </Text>
            </div>
          </Block>

          <ButtonWrapper>
            <Button to="/book-a-demo">{t("book-a-demo")}</Button>
          </ButtonWrapper>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default SectionOffer;
