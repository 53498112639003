import styled from "styled-components";
import Button from "../../../components/Button";
import {
  BackgroundImage,
  Content,
  ContentWrapper,
  Heading,
  SubHeading,
  Text,
} from "../../style";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  /* background: url("/assets/main_background_textured.jpg"); */
  background-color: #efefef;

  background-size: cover;
  position: relative;
`;

const SectionMain = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <SubHeading>
            {t("solutions-for-suppliers.main.subheading")}
          </SubHeading>
          <Heading>{t("solutions-for-suppliers.main.heading")} </Heading>
          <Text>{t("solutions-for-suppliers.main.description")}</Text>
          <Button to="/book-a-demo">
            {t("solutions-for-suppliers.main.button")}
          </Button>
        </Content>
        <BackgroundImage />
      </ContentWrapper>
    </Wrapper>
  );
};

export default SectionMain;
