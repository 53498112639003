import styled from "styled-components";
import { topMargin } from "../../constants";
import { ContentWrapper } from "../style";

const Wrapper = styled.div`
  padding-top: ${topMargin}px;
  min-height: 100vh;
`;

const Privacy = () => {
  return (
    <Wrapper>
      <ContentWrapper>
        <h2>Privacy Policy</h2>
        <p>
          1. Introduction<br></br>
          Welcome to EcoConnect Hub (&quot;we,&quot; &quot;our,&quot; or
          &quot;us&quot;). We are committed to protecting your privacy and
          ensuring your personal information is handled safely and responsibly.
          This Privacy Policy outlines how we collect, use, share, and protect
          your personal information.
        </p>
        <p>
          2. Information We Collect<br></br>
          We collect the following types of information:<br></br>- Personal
          Information: This may include your name, email address, phone number,
          company name, job title, and any other information you provide when
          you register or interact with our platform.<br></br>- Usage Data: We
          may collect information about how you interact with the EcoConnect
          Hub, such as your IP address, browser type, device type, operating
          system, and pages visited.<br></br>- Cookies and Tracking
          Technologies: We use cookies and similar tracking technologies to
          enhance your experience on our platform. You can control the use of
          cookies through your browser settings.
        </p>
        <p>
          3. How We Use Your Information<br></br>
          We use your information for the following purposes:<br></br>- To
          Provide and Improve Our Services: We use your personal information to
          operate, maintain, and enhance the EcoConnect Hub, as well as to
          provide customer support.<br></br>- To Communicate With You: We may
          use your information to send you updates, newsletters, marketing
          materials, and other communications related to our services.<br></br>-
          For Analytics: We use usage data to analyze trends, track user
          activity, and improve our platform&#39;s functionality and
          performance.<br></br>- To Comply With Legal Obligations: We may use
          your information to comply with legal and regulatory requirements.
        </p>
        <p>
          4. Sharing Your Information<br></br>
          We do not sell or share your personal information with third parties
          except as necessary to provide our services, comply with the law, or
          protect our rights. Specifically, we may share your information with:
          <br></br>- Service Providers: We may share your information with
          third-party service providers who assist us in operating our platform,
          conducting our business, or serving our users.<br></br>- Legal
          Compliance: We may disclose your information if required by law, such
          as to comply with a subpoena, or if we believe that such action is
          necessary to protect the rights, property, or safety of our company,
          our users, or others.<br></br>
        </p>
        <p>
          5. Data Security<br></br>
          We take appropriate technical and organizational measures to protect
          your personal information from unauthorized access, use, disclosure,
          alteration, or destruction. However, please be aware that no security
          measures are completely foolproof.
        </p>
        <p>
          6. Your Rights You have the right to:<br></br>- Access Your
          Information: Request access to the personal information we hold about
          you.<br></br>- Correct Your Information: Request corrections to your
          personal information if it is inaccurate or incomplete.<br></br>-
          Delete Your Information: Request the deletion of your personal
          information, subject to certain exceptions.<br></br>- Opt-Out of
          Communications: Opt-out of receiving marketing communications from us
          by following the unsubscribe link in the communication or contacting
          us directly.
        </p>
        <p>
          7. Data Retention<br></br>
          We retain your personal information only for as long as necessary to
          fulfill the purposes for which it was collected or as required by law.
        </p>
        <p>
          8. International Transfers<br></br>
          Your personal information may be transferred to and processed in
          countries other than your country of residence. These countries may
          have different data protection laws than your own. We take appropriate
          measures to ensure that your personal information remains protected.
        </p>
        <p>
          9. Changes to This Privacy Policy<br></br>
          We may update this Privacy Policy occasionally to reflect changes in
          our practices or for other operational, legal, or regulatory reasons.
          We will notify you of any material changes by posting the new Privacy
          Policy on our platform and updating the &quot;Effective Date.&quot;
        </p>
        <p>
          10. Contact Us<br></br>
          If you have any questions or concerns about this Privacy Policy, or if
          you would like to exercise your rights, please contact us at
          hello@ecoconnect-hub.com
        </p>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Privacy;
