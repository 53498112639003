import styled from "styled-components";
import { darkGreen, topMargin } from "../../constants";
import Button from "../../components/Button";
import Form from "../../components/Form";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "../style";

const Wrapper = styled.div`
  /* background: url("/assets/main_background_textured.jpg"); */
  background-color: #efefef;

  background-size: cover;
  padding-top: ${topMargin}px;
  min-height: calc(100vh - 59px);
`;

const Content = styled.div`
  padding: 50px 0;
  display: flex;
  flex-direction: column; /* Stack items vertically on mobile */
  gap: 40px;
  color: ${darkGreen};

  @media (min-width: 768px) {
    flex-direction: row; /* Horizontal layout for larger screens */
    gap: 5%;
  }
`;

const Contacts = styled.div`
  flex-basis: 80%;
`;

const Heading = styled.h1`
  font-size: 30px;
  margin: 0 0 10px 0;
  @media (min-width: 768px) {
    font-size: 50px;
  }
`;

const Subheading = styled.div`
  font-size: 20px;
  padding-bottom: 30px;
  @media (min-width: 768px) {
    font-size: 25px;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  @media (min-width: 768px) {
    flex-direction: row;
    
  }
`;

const ContactEL = styled.div`
  flex: 1;
  flex-shrink: none;
`;

const ContactName = styled.div`
  font-size: 20px;
  font-weight: bold;
  @media (min-width: 768px) {
    font-size: 30px;
    padding-bottom: 20px;
  }
`;

const ContactValue = styled.div`
  font-size: 20px;
`;

const Socials = styled.div`
  display: flex;
  gap: 15px;
`;

const Icon = styled.a`
  display: inline-block;
  img {
    height: 40px;
  }
`;

const BookDemo = () => {
  const { t } = useTranslation();

  const email = 'ecoconnecthub@gmail.com'

  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <Contacts>
            <Heading>{t("book-a-demo-page.let-us-help-you")}</Heading>
            <Subheading>{t("book-a-demo-page.reach-out")}</Subheading>
            <Flex>
              <ContactEL>
                <ContactName>{t("book-a-demo-page.phone")}</ContactName>
                <ContactValue>{t("book-a-demo-page.tel-number")}</ContactValue>
              </ContactEL>

              <ContactEL>
                <ContactName>{t("book-a-demo-page.email")}</ContactName>
                <ContactValue>
                  {t("book-a-demo-page.email-address")}
                </ContactValue>
              </ContactEL>
            </Flex>

            <Flex>
              <ContactEL>
                <Socials>
                  <Icon href="">
                    <img src="/assets/icons/facebook.png" alt="facebook"></img>
                  </Icon>
                  <Icon href="">
                    <img src="/assets/icons/linkedin.png" alt="linkedin"></img>
                  </Icon>
                  <Icon href="">
                    <img src="/assets/icons/insta.png" alt="instagram"></img>
                  </Icon>
                </Socials>
              </ContactEL>

              <ContactEL>
                <Button $isWhite to={`mailto:${email}`}>
                  {t("book-a-demo-page.contact-us")}
                </Button>
              </ContactEL>
            </Flex>
          </Contacts>

          <Form />
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default BookDemo;
