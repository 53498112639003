import "./App.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Header from "./components/Header";
import Main from "./pages/Main";
import BookDemo from "./pages/BookDemo";
import { Routes, Route, Navigate } from "react-router-dom";
import { SolutionsForAcademy, SolutionsForBrands } from "./pages/Solutions";
import SolutionsForSuppliers from "./pages/Solutions/ForSuppliers";
import ComparePlans from "./pages/ComparePlans";
import OurCompany from "./pages/OurCompany";
import Footer from "./components/Footer";
import Terms from "./pages/TermsAndPrivacy/terms";
import Cookies from "./pages/TermsAndPrivacy/cookies";
import Privacy from "./pages/TermsAndPrivacy/privacy";

function App() {
  return (
    <I18nextProvider i18n={i18n} defaultNS={"translation"}>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/book-a-demo" element={<BookDemo />} />
        <Route
          path="/solutions"
          element={<Navigate to="/solutions/for-researchers" />}
        />
        <Route
          path="/solutions/for-researchers"
          element={<SolutionsForAcademy />}
        />
        <Route
          path="/solutions/for-brands"
          element={<SolutionsForBrands />}
        />
        <Route
          path="/solutions/for-suppliers"
          element={<SolutionsForSuppliers />}
        />
        <Route path="/compare-plans" element={<ComparePlans />} />
        <Route path="/our-company" element={<OurCompany />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/cookie-policy" element={<Cookies />} />
      </Routes>
      <Footer />
    </I18nextProvider>
  );
}

export default App;
