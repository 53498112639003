import styled from "styled-components";
import Button from "../../../components/Button";
import { darkGreen, lightGrey } from "../../../constants";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "../../style";

const Wrapper = styled.div`
  background-color: ${lightGrey};
  background-size: cover;
`;

const Content = styled.div`
  padding: 50px 0;
  color: ${darkGreen};
`;

const Heading = styled.div`
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
  @media (min-width: 768px) {
    font-size: 50px;
  }
`;

const Column = styled.div`
  flex-basis: 45%;
`;

const Text = styled.p`
  font-size: 18px;

  line-height: 1.3;
  @media (min-width: 768px) {
    font-size: 24px;

  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 0 0 40px 0;
  @media (min-width: 768px) {
    flex-direction: row-reverse;
    padding: 40px 0;
    gap: 50px;
  }
`;

const Image = styled.div`
  flex-basis: 55%;
  img {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const SectionHow = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <Heading>{t("solutions-for-brands.how-we-help.heading")}</Heading>
          <Flex>
            <Column>
              <Text>{t("solutions-for-brands.how-we-help.text1")}</Text>
              <Text>{t("solutions-for-brands.how-we-help.text2")}</Text>
              <Text>{t("solutions-for-brands.how-we-help.text3")}</Text>
            </Column>
            <Image>
              <img src="/assets/brand_promise_image.jpg" alt="trees" />
            </Image>
          </Flex>
          <ButtonWrapper>
            <Button to="/compare-plans">
              {t("solutions-for-brands.how-we-help.button")}
            </Button>
          </ButtonWrapper>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default SectionHow;
