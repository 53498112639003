import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ContentWrapper } from "../../style";
import { darkGreen } from "../../../constants";

const Wrapper = styled.div`
  /* background: url("/assets/main_background_textured.jpg"); */
  background-color: #efefef;

  background-size: cover;
`;

const Heading = styled.h1`
  font-size: 30px;
  font-weight: 800;
  line-height: 1.3;
  margin-top: 0;
  @media (min-width: 768px) {
    font-size: 77px;
  }
`;

const Content = styled.div`
  padding: 50px 0;
  color: ${darkGreen};
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 70px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const SubHeading = styled.div`
  font-size: 20px;
  font-weight: 800;
  font-style: italic;
  line-height: 1.2;
  padding-bottom: 10px;
  @media (min-width: 768px) {
    font-size: 40px;
  }
`;

const TextElement = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  @media (min-width: 768px) {
    gap: 50px;
  }
`;

const Icon = styled.img`
  width: 60px;
  display: block;
  @media (min-width: 768px) {
    width: 100px
  }
`;

const SectionWhy = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <Heading>{t("main.why-ecoconnect.heading")}</Heading>

          <Text>
            <TextElement>
              <Icon src="/assets/icons/expertise.png"></Icon>
              <div>
                <SubHeading>
                  {t("main.why-ecoconnect.sustainability-expertise.subheading")}
                </SubHeading>
                <div>
                  {t(
                    "main.why-ecoconnect.sustainability-expertise.description",
                  )}
                </div>
              </div>
            </TextElement>
            <TextElement>
              <Icon src="/assets/icons/reliable_data.png"></Icon>
              <div>
                <SubHeading>
                  {t("main.why-ecoconnect.reliable-data.subheading")}
                </SubHeading>
                <div>{t("main.why-ecoconnect.reliable-data.description")}</div>
              </div>
            </TextElement>
            <TextElement>
              <Icon src="/assets/icons/tools.png"></Icon>
              <div>
                <SubHeading>
                  {t("main.why-ecoconnect.secure-tools.subheading")}
                </SubHeading>
                <div>{t("main.why-ecoconnect.secure-tools.description")}</div>
              </div>
            </TextElement>
          </Text>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default SectionWhy;
