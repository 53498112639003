import styled from "styled-components";
import { topMargin } from "../../constants";
import SectionMain from "./main";
import SectionWhy from "./why";
import SectionFor from "./for";

const Wrapper = styled.div`
  padding-top: ${topMargin}px;
  min-height: 100vh;
`;

const MainPage = () => {
  return (
    <Wrapper>
      <SectionMain />

      <SectionFor />

      <SectionWhy />
    </Wrapper>
  );
};

export default MainPage;
