import React  from 'react';
import styled from "styled-components";
import { darkGreen, lightGrey, topMargin } from "../../constants";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "../style";

const Wrapper = styled.div`
  background-color: ${lightGrey};
  background-size: cover;
  min-height: 100vh;
  padding-top: ${topMargin}px;
`;

const Content = styled.div`
  padding: 50px 0px;
  color: ${darkGreen};
  font-size: 18px;
`;

const Heading = styled.div`
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  color: ${darkGreen};
  @media (min-width: 768px) {
    font-size: 60px;
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: calc(100% / 2);
  padding: 20px;

  font-size: 16px;
  line-height: 1.5;
`;

const BlockHeading = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

const List = styled.ul``;

const Text = styled.p``;

const SubHeading = styled.div`
  text-align: center;
  font-size: 16px;
`;

const Flex = styled.div`
  display: flex;
  align-items: stretch;
  gap: 50px;
  padding: 40px 0;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Image = styled.div`
  margin: 0 auto;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;

  img {
    height: 100%;
  }

  @media (min-width: 768px) {
    width: 300px;
    height: 300px;
  }
`;

const OurCompany = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <Heading>{t("our-company.heading")}</Heading>
          <Flex>
            <Block>
              <Image>
                <img src="/assets/ceo_image.jpg" alt="ceo" />
              </Image>
              <BlockHeading>{t("our-company.ceo.name")}</BlockHeading>
              <SubHeading>{t("our-company.ceo.title")}</SubHeading>
              <Text>{t("our-company.ceo.description")}</Text>
              <List>
                {Object.values(
                  t("our-company.ceo.skills", {
                    returnObjects: true,
                  }),
                ).map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </List>
            </Block>

            <Block>
              <Image>
                <img src="/assets/cpo_image.jpeg" alt="cpo" />
              </Image>
              <BlockHeading>{t("our-company.cpo.name")}</BlockHeading>
              <SubHeading>{t("our-company.cpo.title")}</SubHeading>
              <Text>{t("our-company.cpo.description")}</Text>
              <List>
                {Object.values(
                  t("our-company.cpo.skills", {
                    returnObjects: true,
                  }),
                ).map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </List>
            </Block>
          </Flex>
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default OurCompany;
